<template>
  <!-----------footer--------------->
   <section class="footer type_two pdt_100  pdb_70">
      <div class="container">
         <div class="row">
            <!---------row----------->
            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
               <!---------col----------->
               <div class="footer_widgets type_two">
                 
                  <div class="widget_content">
                     <p>Assessment of the COVID-19 Response in Eastern, Central and Western Africa project conducted in DRC, Ghana, Nigeria, Senegal and Uganda</p>
                     <router-link :to="{name:'moreaboutproject'}"  class="read_more tp_one">  View More<span class="linearicons-arrow-right"></span></router-link>
                  </div>
               </div>
               <!---------col-end---------->
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12">
               <!---------col----------->
               <div class="footer_widgets type_two">
                  <h3 class="widgets_title">Products and More</h3>
                  <div class="widget_content">
                     <ul class="links">
                        <li ><router-link :to="{name:'briefs'}">Briefs</router-link></li>
                        <li ><router-link :to="{name:'reports'}" >Reports</router-link></li>
                        <li ><router-link :to="{name:'seminars'}">Seminars</router-link></li>
                        <li><router-link :to="{name:'practice'}" >Community of Practice</router-link></li>
                     </ul>
                  </div>
               </div>
               <!---------col-end---------->
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12">
               <!---------col----------->
               <div class="footer_widgets type_two">
                  <h3 class="widgets_title">Media and Tools</h3>
                  <div class="widget_content">
                     <ul class="links">
                        <li><router-link :to="{name:'news'}">News Updates</router-link></li>
                        <li><router-link :to="{name:'blogs'}">Blogs</router-link></li>
                        <li><router-link :to="{name:'aboutcovid'}">About Covid 19</router-link></li>
                        <li><router-link :to="{name:'informationtool'}">Information Tool</router-link></li>
                     </ul>
                  </div>
               </div>
               <!---------col-end---------->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
               <!---------col----------->
               <div class="footer_widgets type_two">
                  <h3 class="widgets_title">Contacts</h3>
                  <div class="widget_content">
                     <h2 class="emergency_contact"><a href="#">+256 414 543872</a></h2>
                     <ul class="contact_all">
                        <li><a href="#"><span class="linearicons-home4"></span>New Mulago Hill Road, Mulago Kampala, Uganda</a></li>
                        <li><a href="#"><span class="linearicons-envelope-open"></span>Email: <small>dean@musph.ac.ug</small></a></li>
                     </ul>
                  </div>
               </div>
               <!---------col-end---------->
            </div>
            <!----------row-end---------->
         </div>
      </div>
   </section>
   <!-----------footer-end-------------->
   <!-----------footerlast UNWELL?--------------->
   <section class="footerlast type_two  pdb_20 pdt_50">
      <div class="container">
         <div class="row">
            <!---------row----------->
            <div class="col-lg-8 col-md-12">
               <!---------col----------->
               <ul>
                  <li>Copyright © 2022 Makerere University - School of Public Health</li>
                  <!-- <li>|</li>
                  <li><a href="#">Term of Use </a> <a href="#">  Privacy Policy  </a> <a href="#">  Impressum</a></li> -->
               </ul>
               <!---------col-end---------->
            </div>
            <div class="col-lg-4 col-md-12 text-right">
               <!---------col----------->
               <p>Developed by <small> <a href="">Deepcode Innovations LTD</a></small></p>
               <!---------col-end---------->
            </div>
            <!----------row-end---------->
         </div>
      </div>
   </section>
   <!-----------footerlast-end-------------->





         <!--Scroll to top-->
      <a href="# " id="scroll" class="default-bg " style="display: inline;"><span class="fa fa-angle-up "></span></a>
      <!---------mbile-navbar----->
      <div class="bsnav-mobile ">
         <div class="bsnav-mobile-overlay"></div>
         <div class="navbar ">
            <button class="navbar-toggler toggler-spring mobile-toggler"><span class="linearicons-cross close_arrow"></span></button>
            <div class="search_form">
               <form>
                  <div class="form-group">
                     <input type="text" name="search" placeholder="Search..." />
                     <button class="serach_btn" type="submit"><i class="linearicons-magnifier search_icon"></i></button>
                  </div>
               </form>
            </div>
         </div>
      </div>
      <!---------mbile-navbar----->
      <!-- /.side-menu__block -->
      <div class="side-menu__block">
      
         <!-- /.side-menu__block-overlay -->
         <div class="side-menu__block-inner">
            <div class="row ">
               <div class="col-lg-12 ">
                  <div class="side-menu__block-contact ">
                     <span class="side_menu_close linearicons-cross close_arrow"> </span>
                     <h2>Get in touch</h2>
                     <p>  If you have any question, please fill below  the contact form.</p>
                     <div class="form_outer">
                        <form>
                           <div class="row">
                              <div class="col-lg-12">
                                 <div class="from_group">
                                    <input type="text" name="name" placeholder="Your Name" />
                                    <small class="linearicons-user"></small>
                                 </div>
                              </div>
                              <div class="col-lg-12">
                                 <div class="from_group">
                                    <input type="email" name="email" placeholder="Your Email Address" />
                                    <small class="linearicons-envelope-open"></small>
                                 </div>
                              </div>
                              <div class="col-lg-12">
                                 <div class="from_group">
                                    <input type="text" name="phone" placeholder="Your Phone" />
                                    <small class="linearicons-telephone"></small>
                                 </div>
                              </div>
                      
                              <div class="col-lg-7">
                                 <div class="form-group mg_top check_box">
                                    <input name="checkbox" type="checkbox" id="test7" required="required">
                                    <label for="test7">I accept the <a href="#" target="_blank">Privacy Policy.</a></label>
                                 </div>
                              </div>
                              <div class="col-lg-5">
                                 <div class="from_group">
                                    <button class="submit_btn" type="submit">Submit</button>
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
        
                  <!-- /.side-menu__block-contact -->
                  <p class="side-menu__block__text site-footer__copy-text">
                     <a href="#">Copyright © 2022 Makerere University - School of Public Health</a> 
                  </p>
                 <!-- <p>Developed by <small> <a href="">Deepcode Innovations LTD</a></small></p> -->
               </div>
            </div>
            <!-- /.side-menu__block-inner -->
         </div>
      </div>
</template>
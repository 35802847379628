<template>
<div class="page_wapper">
    <!-- <div class="preloader">
        <div class="preloader_box">
            <div class="loader">
                <img src="/assets/image/preloader.png" class="img-fluid" alt="img">
                <p>please Wait...</p>
            </div>
        </div>
    </div> -->
    <pageheader/>
    <main class="main-content">
        <router-view/>
    </main>
    <pagefooter/>
</div>
</template>
<script>
import pageheader from './includes/header.vue'
import pagefooter from './includes/footer.vue'
export default {
    components:{
        pageheader,
        pagefooter
    },


    mounted(){

    var scripts = [
    "/assets/js/all.js",
    "/assets/js/custom.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    })

    },


    created(){

    var scripts = [
    "/assets/js/all.js",
    "/assets/js/custom.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    })

    }
}
</script>
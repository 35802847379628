<template>
    <header class="header_v1">
            <!--Header-v1--->

            <section class="navbar_outer">
               <div class="navbar navbar-expand-lg  bsnav bsnav-sticky bsnav-sticky-slide" style="background-color:white; border-bottom:8px solid #00A551; ">
                  <div class="container">
                     <a class="navbar-brand" href="/"><img src="@/assets/image/logo-default.png" class="img-fluid" alt="img"></a>
                     <button class="navbar-toggler toggler-spring"><span class="navbar-toggler-icon"></span></button>

                     <div class="collapse navbar-collapse scroll-nav">
                        <ul class="navbar-nav navbar-mobile navbar_left  ml-auto" id="navbarnav">
                           <li class="nav-item nav_item ">
                              <a class="nav-link link_hd" href="/">Home </a>
                           </li>

                           <li class="nav-item nav_item drop_down">
                              <a class="nav-link link_hd" href="#">About the Project</a>
                              <ul class="navbar-nav submenu">
                                 <li class="nav-item"><a href="/more-about-project" class="nav-link">About the Project</a></li>
                                 <li class="nav-item"><a href="/project-team" class="nav-link">Project Team</a></li>
                                 <li class="nav-item"><router-link class="nav-link" :to="{name:'messagefromtheprincipalinvestigator'}">Principal Investigator's Message</router-link></li>
                              </ul>
                           </li>

                           <li class="nav-item nav_item">
                              <router-link :to="{name:'practice'}" class="nav-link link_hd" href="#">Community of Practice</router-link>
                           </li>

                           <li class="nav-item nav_item drop_down">
                              <a class="nav-link link_hd" href="#">Products</a>
                              <ul class="navbar-nav submenu">
                                 <li class="nav-item"><router-link class="nav-link" :to="{name:'briefs'}">Briefs</router-link></li>
                                 <li class="nav-item"><router-link class="nav-link" :to="{name:'reports'}" >Reports</router-link></li>
                                 <li class="nav-item"><router-link class="nav-link" :to="{name:'seminars'}">Webinars and Conferences</router-link></li>
                              </ul>
                           </li>

                           <li class="nav-item nav_item drop_down">
                              <a class="nav-link link_hd" href="#"> Media  </a>
                              <ul class="navbar-nav submenu">
                                 <li class="nav-item"><router-link :to="{name:'blogs'}" class="nav-link" >Blogs</router-link></li>
                                 <li class="nav-item"><router-link :to="{name:'news'}" class="nav-link" >News</router-link></li> 
                              </ul>
                           </li>
                           
                        </ul>
                        <ul class="navbar-nav navbar-mobile navbar_right">
                           <li  class="nav-item  drop_down">
                              <a  href="#"> <i class="linearicons-magnifier search_icon"></i></a>
                              <ul class="navbar-nav nav_search_submenu submenu">
                                 <li class="nav_search">
                                    <form>
                                       <div class="form_group">
                                          <input type="text" name="search" placeholder="Enter Keywords..." />
                                          <button class="search_btn" type="submit"><i class="linearicons-arrow-right"></i></button>
                                       </div>
                                    </form>
                                 </li>
                              </ul>
                           </li>
                           <li><router-link :to="{name:'informationtool'}" class="theme_btn tp_two" ><span class="linearicons-calendar-text icon"></span>Information Tool</router-link></li>
                        </ul>
                     </div>

                     <div class="options_menu ">
                        <!-- /.site-header__cart -->
                        <a href="#" class="site-header__sidemenu-nav side-menu__toggler">
                           <span class="site-header__sidemenu-nav-line"></span>
                           <!-- /.site-header__sidemenu-nav-line -->
                           <span class="site-header__sidemenu-nav-line"></span>
                           <!-- /.site-header__sidemenu-nav-line -->
                           <span class="site-header__sidemenu-nav-line"></span>
                           <!-- /.site-header__sidemenu-nav-line -->
                           <!-- /.site-header__sidemenu -->
                        </a>
                     </div>

                  </div>
                  
               </div>
               

               
               <!--Header-v1-end-->
            </section>
            
         </header>
       
</template>